export default {
  '': '',
  ' ': ' ',
  "''": "''",
  ' format file.': ' format file.',
  ' segments. Extra charge may be applied.': ' segments. Extra charge may be applied.',
  ' text messages)': ' text messages)',
  "'s QR Code": "'s QR Code",
  'Account': 'Account',
  'Account Info': 'Account Info',
  'Activated': 'Activated',
  'Active': 'Active',
  'Add': 'Add',
  'Add Merchant': 'Add Merchant',
  'Add new Referral': 'Add new Referral',
  'Add Partner': 'Add Partner',
  'Add Referal': 'Add Referral',
  'Add Role': 'Add Role',
  'Add Staff': 'Add Staff',
  'Add vip card successfully': 'Add vip card successfully',
  'Address': 'Address',
  'AD Banner': 'AD Banner',
  'AD Banner Visibility': 'AD Banner Visibility',
  'AD Image is required': 'AD Image is required',
  'After Tax': 'After Tax',
  'After VIP claimed coupon': 'After VIP claimed coupon',
  'Agree to': 'Agree to',
  'Agree to terms and conditions': 'Agree to terms and conditions',
  'All': 'All',
  'All Coupons': 'All Coupons',
  'All Stores': 'All Stores',
  'All Vip Cards': 'All Vip Cards',
  'Amount': 'Amount',
  'Amount1': 'Amount',
  'Amount2': 'Amount',
  'Amount cannot be empty': 'Amount cannot be empty',
  'Amount is invalid': 'Amount is invalid',
  'Amount must be positive': 'Amount must be positive',
  'An exception happened': 'An exception happened',
  'Api Key': 'Api Key',
  'Apply': 'Apply',
  'Are you sure to delete this banner?': 'Are you sure to delete this banner?',
  'Are you sure to delete this card?': 'Are you sure to delete this card?',
  'Are you sure to delete this referal?': 'Are you sure to delete this referral?',
  'Are you sure to delete this staff?': 'Are you sure to delete this staff?',
  'Are you sure to delete this tag?': 'Are you sure to delete this tag?',
  'Are you sure to disable this card?': 'Are you sure to disable this card?',
  'Are you sure to text all the members?': 'Are you sure to text all the members?',
  'Available': 'Available',
  'Available Date': 'Available Date',
  'Available from': 'Available from',
  'Back': 'Back',
  'Balance': 'Balance',
  'Before Tax': 'Before Tax',
  'Bonus': 'Bonus',
  'By File': 'By File',
  'By Parameters': 'By Parameters',
  'By Time': 'By Time',
  'Campaign name': 'Campaign name',
  'Cancel': 'Cancel',
  'Cancel Redeem ': 'Cancel Redeem ',
  "Can't read qrcode": "Can't read qrcode",
  "Can't send due to insufficient balance, please reload before retry": "Can't send due to insufficient balance, please reload before retry",
  'Cards': 'Cards',
  'Card Details': 'Card Details',
  'Card Holder': 'Card Holder',
  'Card Image': 'Image',
  'Card Info': 'Card Info',
  'Card Name': 'Name',
  'Card Number': 'Card Number',
  'Card number is duplicated at line ': 'Card number is duplicated at line ',
  'Card Number Length': 'Card Number Length',
  'Card Number Length is too small': 'Card Number Length is too small',
  'Card Number Prefix': 'Card Number Prefix',
  "Card Number Prefix can't be longer than Card Number Length": "Card Number Prefix can't be longer than Card Number Length",
  'Card Number Start From': 'Card Number Start From',
  'Card Quantity': 'Card Quantity',
  'Card Quantity is too small': 'Card Quantity is too small',
  'Card Search part1': 'Found',
  'Card Search part2': 'Results',
  'Card Type': 'Type',
  'Cell Number': 'Cell Number',
  'Chain Store': 'Chain Store',
  'Chain Store List': 'Chain Store List',
  'Change Status': 'Change Status',
  'Chinese': 'Chinese',
  'Chinese1': 'Chinese',
  'Chinese Card Name': 'Chinese Card Name',
  'Chinese Coupon Title': 'Chinese Coupon Title',
  'Chinese Description': 'Chinese Description',
  'Chinese Description1': 'Chinese Description',
  'Chinese Description2': 'Chinese Description',
  'Chinese description is required': 'Chinese description is required',
  'Chinese Name': 'Chinese Name',
  'Chinese Point Rules': 'Chinese Point Rules',
  'Chinese Store Brief Introduction': 'Chinese Store Brief Introduction',
  'Chinese Title': 'Chinese Title',
  'Chinese title is required': 'Chinese title is required',
  'Choose file to upload': 'Choose file to upload',
  'Choose from Gallery': 'Choose from Gallery',
  'Choose Language': 'Choose Language',
  'Choose store': 'Choose store',
  'Choose template': 'Choose template',
  'Claimed': 'Claimed',
  'Claim': 'Claim',
  'Claim Coupon': 'Claim',
  'Claim Now': 'Claim Now',
  'Claim On Platform': 'Claim On Platform',
  'Close': 'Close',
  'Complete': 'Complete',
  'Confirm': 'Confirm',
  'Contact Name': 'Contact Name',
  'Contact Us': 'Contact Us',
  'Copy Link': 'Copy Link',
  'Cost Per Message': 'Cost Per Message',
  'Coupon': 'Coupon',
  'Coupon can only be applied by members': 'Coupon can only be applied by members',
  'Coupon Detail': 'Coupon Detail',
  'Coupon Image': 'Coupon Image',
  'Coupon Management': 'Coupon Management',
  'Coupon Numbers': 'Coupon Numbers',
  'Coupon Title': 'Coupon Title',
  'Coupon Total Counts': 'Coupon Total Counts',
  'Coupons available': 'Coupons available',
  'Create Campaign': 'Create Campaign',
  'Create Card': 'Create Card',
  'Create Coupon': 'Create Coupon',
  'Create Date': 'Create Date',
  'Create Event': 'Create Event',
  'Created on': 'Created on',
  'Current Points': 'Current Points',
  'Custom': 'Custom',
  'Customize': 'Customize',
  'Custom Reload': 'Custom Reload',
  'Date of Birth': 'Date of Birth',
  'Days': 'Days',
  'Deduct': 'Deduct',
  'Deduct Rate(Point to Cash Rate)': 'Deduct Rate(Point to Cash Rate)',
  'Default same as AD Banner': 'Default same as AD Banner',
  'Delete': 'Delete',
  'Delete Confirmation': 'Delete Confirmation',
  'Delete Staff': 'Delete Staff',
  'Description': 'Description',
  'Design': 'Design',
  'Detail': 'Detail',
  'Detail Image': 'Detail Image',
  'Disable': 'Disable',
  'Disabled': 'Disabled',
  'Disabled2': 'Disabled',
  'Disable successfully': 'Disable successfully',
  'Discount Amount ($)': 'Discount Amount ($)',
  'Discount Percentage (%)': 'Discount Percentage (%)',
  'Discount Type': 'Discount Type',
  'Description': 'Description',
  'Download': 'Download',
  'Download Member Analyses': 'Download Member Analyses',
  'Download QR Code': 'Download QR Code',
  'Download Report': 'Download Report',
  'Do you agree to applied the free VIP member card': 'Do you agree to applied the free VIP member card',
  'Draft': 'Draft',
  'Duplicate': 'Duplicate',
  'Edit': 'Edit',
  'Edit Coupon': 'Edit Coupon',
  'Edit Referal': 'Edit Referral',
  'Edit Role': 'Edit Role',
  'Edit Staff': 'Edit Staff',
  'Email': 'Email',
  'Enable SMS Notification': 'Enable SMS Notification',
  'End Date': 'End Date',
  'English': 'English',
  'English1': 'English',
  'English Card Name': 'English Card Name',
  'English Coupon Title': 'English Coupon Title',
  'English Description': 'English Description',
  'English Description1': 'English Description',
  'English Description2': 'English Description',
  'English description is required': 'English description is required',
  'English Name': 'English Name',
  'English Point Rules': 'English Point Rules',
  'English Store Brief Introduction': 'English Store Brief Introduction',
  'English Title': 'English Title',
  'English title is required': 'English title is required',
  'Enter Amount': 'Enter Amount',
  'Enter amount of available days': 'Enter amount of available days',
  'Enter cell number': 'Enter cell number',
  'Enter Cellphone Number': 'Enter Cellphone Number',
  'Enter Code': 'Enter Code',
  'Enter Discount Amount': 'Enter Discount Amount',
  'Enter Discount Percentage': 'Enter Discount Percentage',
  'Enter Manually': 'Enter Manually',
  'Enter password': 'Enter password',
  'Enter Percentage': 'Enter Percentage',
  'Enter phone number': 'Enter phone number',
  'Event Details': 'Event Details',
  'Exceed maximum limit': 'Exceed maximum limit',
  'Exclusive': 'Exclusive',
  'Existing member': 'Existing member',
  'Expired': 'Expired',
  'Expired Date:': 'Expired Date:',
  'Expiry Date': 'Expiry Date',
  'Explore Merchants': 'Explore Merchants',
  'Fail': 'Fail',
  'Failed: insufficient balance': 'Failed: insufficient balance',
  'Female': 'Female',
  'Finished': 'Finished',
  'Fixed Amount': 'Fixed Amount',
  'For Sale': 'For Sale',
  'Forgot password': 'Forgot password',
  'Free Apply': 'Free Apply',
  'From Gallery': 'From Gallery',
  'From Phone Number': 'From Phone Number',
  'Functions': 'Functions',
  'Full Permission': 'Full Permission',
  'Full Size': 'Full Size',
  'Gender': 'Gender',
  'Generate No.': 'Generate No.',
  'Gerenating': 'Gerenating',
  'Get Verification code': 'Get Verification code',
  'Gift Card': 'Gift Card',
  'Have an account already': 'Have an account already',
  'Head Image': 'Head Image',
  'Header String': 'Header String',
  'Headquarter Only': 'Headquarter Only',
  'History1': 'History',
  'Home': 'Home',
  'Home Page': 'Home Page',
  'Home Page Setting': 'Home Page Setting',
  'Home Top Picture': 'Home Top Picture',
  'How many cards to create': 'How many cards to create',
  'How many characters card number contains': 'How many characters card number contains',
  'If an existing member registers an OrangeWallet account, is a second verification required?': 'If an existing member registers an OrangeWallet account, is a second verification required?',
  'If need to enable SMS function, please contact POSKING customer service': 'If need to enable SMS function, please contact POSKING customer service',
  'Image': 'Image',
  'Image size cannot be over 2MB': 'Image size cannot be over 2MB',
  'Import': 'Import',
  'Import success': 'Import success',
  'In Progress': 'In Progress',
  'In Store': 'In Store',
  'In Use': 'In Use',
  'Inactive': 'Inactive',
  'Increase issuance of coupons': 'Increase issuance of coupons',
  'Increasing': 'Increasing',
  'Input phone No./card No./name': 'Input phone No./card No./name',
  'Input Amount': 'Input Amount',
  'Input Bonus': 'Input Bonus',
  'Input to Search': 'Input to Search',
  'Input URL': 'Input URL',
  'Insufficient balance': 'Insufficient balance',
  'Insufficient points': 'Insufficient points',
  'Invalid amount of available days': 'Invalid amount of available days',
  'Invalid code': 'Invalid code',
  'Invalid QR Code': 'Invalid QR Code',
  'Invoice Percentage Discount': 'Invoice Percentage Discount',
  'Invoice Price Discount': 'Invoice Price Discount',
  'Issue Start Date': 'Issue Start Date',
  'Issue End Date': 'Issue End Date',
  'Issued': 'Issued',
  'Issuing': 'Issuing',
  'Item Percentage Discount': 'Item Percentage Discount',
  'Item Price Discount': 'Item Price Discount',
  'Last Month': 'Last Month',
  'Last Updated:': 'Last Updated:',
  'Last 6 Months': 'Last 6 Months',
  'Layout': 'Layout',
  'Less': 'Less',
  'Less than 20 characters': 'Less than 20 characters',
  'Link Copied': 'Link Copied',
  'Link VIP:': 'Link VIP:',
  'List Id': 'List Id',
  'Load': 'Load',
  'Loading': 'Loading',
  'Location': 'Location',
  'Login': 'Login',
  'Logout': 'Logout',
  'Logo and Name': 'Logo and Name',
  'Ludaiwei': 'Ludaiwei',
  'Make sure you entered correct phone number': 'Make sure you entered correct phone number',
  'Male' : 'Male',
  'Main Merchant': 'Main Merchant',
  'Operation Config': 'Operation Config',
  'Manage Coupons': 'Manage Coupons',
  'Manage Member': 'Manage Member',
  'Manage Merchant': 'Manage Merchant',
  'Manage Referal': 'Manage Referral',
  'Manage Role': 'Manage Role',
  'Manage SMS': 'Manage SMS',
  'Manage Staff': 'Manage Staff',
  'Master Merchant': 'Master Merchant',
  'Maximum 20 characters': 'Maximum 20 characters',
  'Maximum 150 characters': 'Maximum 150 characters',
  'Maximum 500 characters': 'Maximum 150 characters',
  'Maximum 1000 characters': 'Maximum 1000 characters',
  'Maximum characters reached': 'Maximum characters reached',
  'Max Quantity': 'Max Quantity',
  'Member': 'Member',
  'Member Discount': 'Member Discount',
  'Member Info': 'Member Info',
  'Merchant': 'Merchant',
  'Merchant Group': 'Merchant Group',
  'Merchant ID': 'Merchant ID',
  'Merchant Info': 'Merchant Info',
  'Merchant List': 'Merchant List',
  'Merchant Login': 'Merchant Login',
  'Merchant Token': 'Merchant Token',
  'Message': 'Message',
  'Mine': 'Mine',
  'Minimum': 'Minimum',
  'Modify Account Info': 'Modify Account Info',
  'Modify Card': 'Modify Card',
  'Modify Event': 'Modify Event',
  'Modify Merchant Info': 'Modify Merchant Info',
  'Modify Password': 'Modify Password',
  'Month': 'Month',
  'More...': 'More...',
  'More Promotions': 'More Promotions',
  'My VIP Cards': 'My VIP Cards',
  'Name': 'Name',
  'Name1': 'Name',
  'New Password': 'New Password',
  'New user': 'New user',
  'No': 'No',
  'No Content': 'No Content',
  'No Coupon': 'No Coupon',
  'No Data': 'No Data',
  'No data created': 'No data created',
  'No data in excel file': 'No data in excel file',
  'No Import Data': 'No Import Data',
  'No Logo, No Name': 'No Logo, No Name',
  'No merchant is selected': 'No merchant is selected',
  'No Vip Card': 'No Vip Card',
  'Notes': 'Notes',
  'Not Required': 'Not Required',
  'N/A': 'N/A',
  'N days after claimed': 'N days after claimed',
  'Offline': 'Offline',
  'Offset': 'Offset',
  'Old Password': 'Old Password',
  'Online': 'Online',
  'Online Order Function': 'Online Order Function',
  'Online order link is required': 'Online order link is required',
  'Only For Referrer': 'Only For Referrer',
  'Only Logo': 'Only Logo',
  'Only Name': 'Only Name',
  'OK': 'OK',
  'OK2': 'OK',
  'Order': 'Order',
  'Others': 'Others',
  'Partial Permission': 'Partial Permission',
  'Partners': 'Partners',
  'Partner': 'Partner',
  'Partner QR Code': 'Partner QR Code',
  'Partner Report': 'Partner Report',
  'Password': 'Password',
  'Pause': 'Pause',
  'Pause Coupon Distribution': 'Pause Coupon Distribution',
  'Pause Coupon Distribution DESC': "Pausing coupon's distribution will hide this coupon in your home page. Coupons that had been claimed are still usable.",
  'Paused': 'Paused',
  'Pay Amount': 'Pay Amount',
  'Pay Cancel': 'Pay Cancel',
  'Pending': 'Pending',
  'Percentage': 'Percentage',
  'Permission Includes': 'Permission Includes',
  'Persional Info': 'Persional Info',
  'Personal Information': 'Personal Information',
  'Phone': 'Phone',
  'Phone number': 'Phone number',
  'Phone number cannot be empty': 'Phone number cannot be empty',
  'Phone number invalid': 'Phone number invalid',
  'Please check SMS on your phone with the number': 'Please check SMS on your phone with the number',
  'Please choose a date': 'Please choose a date',
  'Please confirm your username or password are correct': 'Please confirm your username or password are correct',
  'Please enter amount of available days': 'Please enter amount of available days',
  'Please enter a description': 'Please enter a description',
  'Please enter a number': 'Please enter a number',
  'Please enter a role name': 'Please enter a role name',
  'Please Input Quantity': 'Please Input Quantity',
  'Please Input Verify Code': 'Please Input Verify Code',
  'Please provide a title': 'Please provide a title',
  'Please read and agree to the terms and conditions': 'Please read and agree to the terms and conditions',
  'Please scan membership QR Code OR Coupon QR Code': 'Please scan membership QR Code OR Coupon QR Code',
  'Please select a type': 'Please select a type',
  'Please select available date': 'Please select available date',
  'Please upload ': 'Please upload ',
  'Please upload an image': 'Please upload an image',
  'Points': 'Points',
  'Points Earned': 'Points Earned',
  'Points Used': 'Points Used',
  'Points History': 'Points History',
  'Points to earn : ': 'Points to earn : ',
  'Points used': 'Points used',
  'Point Rate(Total to Point Rate)': 'Ratio of amount to points',
  'Point Rules': 'Point Rules',
  'Preset Reload and Bonus': 'Preset Reload and Bonus',
  'Preset part1': 'Reload',
  'Preset part2': 'Get',
  'Preset part3': 'Bonus',
  'Preview': 'Preview',
  'Preview SMS': 'Preview SMS',
  'Product': 'Product',
  'Quantity cannot be empty': 'Quantity cannot be empty',
  'Quantity can not larger than 2000': 'Quantity can not larger than 2000',
  'Quantity is invalid': 'Quantity is invalid',
  'Quantity must be positive': 'Quantity must be positive',
  'Recall': 'Recall',
  'Recommanded-ADBanner': 'Recommanded resolution is 311*112 with file size less than 2MB, keep visual elements centered',
  'Recommanded-CardPic': 'Recommanded resolution is 343*217 with file size less than 2MB, keep visual elements centered',
  'Recommanded-CouponImage': 'Recommanded resolution is 343*145 with file size less than 2MB, keep visual elements centered',
  'Recommanded-DetailImage': 'Recommanded a width of 343 pixels with file size less than 2MB, keep visual elements centered',
  'Recommanded-HomeTopPic': 'Recommanded resolution is 375*265 with file size less than 2MB, keep visual elements centered',
  'Recommanded-ReferalImage': 'Recommanded resolution is 343*260 with file size less than 2MB, keep visual elements centered',
  'Recommanded-Splash': 'Recommanded resolution is 375*812 with file size less than 2MB, keep visual elements centered',
  'Recommanded-TopPic': 'Recommanded resolution is 343*183 with file size less than 2MB, keep visual elements centered',
  'Redeem': 'Redeem',
  'Redeem ': 'Redeem ',
  'Redeem Rate(Point to Cash Rate)': 'Redeem Rate(Point to Cash Rate)',
  'Referrals': 'Referrals',
  'Referal': 'Referal',
  'Referal Detail': 'Referral Detail',
  'Referal Type': 'Referral Type',
  'Register': 'Register',
  'Reimburse Method': 'Reimburse Method',
  'Reload': 'Reload',
  'Reload Amount': 'Reload Amount',
  'Reload Cancel': 'Reload Cancel',
  'Reload History': 'Reload History',
  'Remind VIP coupon is about to expire': 'Remind VIP coupon is about to expire',
  'Required': 'Required',
  'Resendpart1': 'You can resend in',
  'Resendpart2': 'seconds',
  'Resendpart3': 'No code received?',
  'Resendpart4': 'Resend',
  'Resend': 'Resend',
  'Resend Code in': 'Resend Code in',
  'Reset Password': 'Reset Password',
  'Results': 'Results',
  'Resume': 'Resume',
  'Resume Coupon Distribution': 'Resume Coupon Distribution',
  'Resume Coupon Distribution DESC': "Resuming coupon's distribution will allow users to see this coupon in your home page, and be able to claim it.",
  'Role': 'Role',
  'Role Name': 'Role Name',
  'Rules': 'Rules',
  'Save': 'Save',
  'Scan to Redeem': 'Scan to Redeem',
  'Scan QR Code': 'Scan QR Code',
  'Scan your card here': 'Scan your card here',
  'Schedule for Later': 'Schedule for Later',
  'Scheduled': 'Scheduled',
  'Search': 'Search',
  'Search Store and Coupons': 'Search Store and Coupons',
  'seconds': 'seconds',
  'Select': 'Select',
  'Select a Product': 'Select a Product',
  'Select Coupon': 'Select Coupon',
  'Select Date': 'Select Date',
  'Select file(xlsx) to import': 'Select file(xlsx) to import',
  'Selected Stores': 'Selected Stores',
  'Select Spread Type': 'Select Spread Type',
  'Select Type': 'Select Type',
  'Self': 'Self',
  'Send': 'Send',
  'Sending': 'Sending',
  'Send Now': 'Send Now',
  'Send time must be at least 1 hour from now': 'Send time must be at least 1 hour from now',
  'Sent': 'Sent',
  'Sent successfully': 'Sent successfully',
  'Sent with Success': 'Sent with Success',
  'Set URL': 'Set URL',
  'Setting': 'Settings',
  'Setup Password': 'Setup Password',
  'Sign In': 'Sign In',
  'SMS function not available': 'SMS function not available',
  'SMS History': 'SMS History',
  'SMS Notification': 'SMS Notification',
  'SMS Preview': 'SMS Preview',
  'Sold': 'Sold',
  'Something Worng, Please try it later.': 'Something Worng, Please try it later.',
  'Seconds': 'Seconds',
  'SMS Language': 'SMS Language',
  'SMS Management': 'SMS Management',
  'SMS Notification Cannot Be Enabled': 'SMS Notification Cannot Be Enabled',
  'SMS types including': 'SMS types including',
  'Specified start and end date': 'Specified start and end date',
  'Spend & Reload': 'Spend & Reload',
  'Splash Page': 'Splash Page',
  'Spread Type': 'Spread Type',
  'Staff Info': 'Staff Info',
  'StaffName': 'Name',
  'Start Date': 'Start Date',
  'Status': 'Status',
  'Store': 'Store',
  'Store Brief Introduction': 'Store Brief Introduction',
  'Store Detail': 'Store Detail',
  'Store QRCode': 'Store QRCode',
  'Submit': 'Submit',
  'Submit Request': 'Submit Request',
  'Success': 'Success',
  'Successfully reloaded': 'Successfully reloaded',
  'System': 'System',
  'Tag': 'Tag',
  'Tag List': 'Tag List',
  'Take photo': 'Take photo',
  'Task in progress': 'Task in progress',
  'terms and conditions': 'terms and conditions',
  'Text message': 'Text message',
  'Theme Color': 'Theme Color',
  'The "Only For Referrer" coupon does not support SMS notification to users.': 'The "Only For Referrer" coupon does not support SMS notification to users.',
  'The figure is correct to two decimal places': 'The figure is correct to two decimal places',
  'This card is not in use': 'This card is not in use',
  'This is not your store card': 'This is not your store card',
  'This is not your store coupon': 'This is not your store coupon',
  'This message will be sent as ': 'This message will be sent as ',
  'This Month': 'This Month',
  'This Year': 'This Year',
  'Time Zone': 'Time Zone',
  'Title': 'Title',
  'Token': 'Token',
  'Top Image': 'Top Image',
  'Top Picture': 'Top Picture',
  'Total Amount': 'Total Amount',
  'Total Cost': 'Total Cost',
  'Total number should be no more than 2000': 'Total number should be no more than 2000',
  'Total Sent': 'Total Sent',
  'Total Spent': 'Total Spent',
  'To be displayed on store search page': 'To be displayed on store search page',
  'Transaction Records': 'Transaction Records',
  'Try Again': 'Try Again',
  'Type': 'Type',
  'Type your SMS content here...': 'Type your SMS content here...',
  'Unable to send': 'Unable to send',
  'Unknown': 'Unknown',
  'Unknown Parameter': 'Unknown Parameter',
  'Unknown Merchant': 'Unknown Merchant',
  'Update status successfully': 'Update status successfully',
  'Update vip card successfully': 'Update vip card successfully',
  'Upload': 'Upload',
  'Upload picture': 'Upload picture',
  'Used': 'Used',
  'Use Manually': 'Use Manually',
  'Use points': 'Use points',
  'Valid In': 'Valid In',
  'Valid Until': 'Valid Until',
  'Valid Until ': 'Valid Until ',
  'Verification Code': 'Verification Code',
  'Verification Code is sended to': 'Verification Code is sent to',
  'Verify': 'Verify',
  'Verify Error': 'Verify Error',
  'VIP balance change': 'VIP balance change',
  'VIP Card': 'VIP Card',
  'VIP Notification': 'VIP Notification',
  'VIP points change': 'VIP points change',
  'Manage VIP Card': 'Manage VIP Card',
  'Welcome': 'Welcome',
  'Wallet': 'Wallet',
  'What the card number start with': 'What the card number start with',
  'Which number to start numbering': 'Which number to start numbering',
  'Year to Date': 'Year to Date',
  'Yes': 'Yes',
  '(Totally send ': '(Totally send ',
  // more translation keys...
}
